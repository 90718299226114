export const industryDropDown = [
  [null, "Choose your industry"],
  ["AG", "Agriculture"],
  ["FMCG", "FMCG"],
  ["GV", "Government"],
  ["HP", "Hospitality"],
  ["IT", "Information Technology"],
  ["IN", "Insurance"],
  ["MC", "Management Consulting"],
  ["NGO", "NGO"],
  ["OTH", "Others"],
];

export const countryDropDown = [
  [null, "Choose your country"],
  ["NG", "Nigeria"],
  ["GH", "Ghana"],
  ["O", "Others"],
];

export const businessRole = [
  [null, "Choose your role"],
  ["CEO", "CEO"],
  ["CTO", "CTO"],
  ["SWE", "Software Engineer"],
  ["PDM", "Product Manager"],
  ["OPM", "Operations Manager"],
  ["PJM", "Project Manager"],
  ["MKM", "Marketing Manager"],
  ["FNM", "Finance Officer/Manager"],
  ["BSA", "Business Analyst"],
  ["SRP", "Sales Representative"],
  ["BSO", "Business Owner"],
  ["PDD", "Product Designer"],
  ["OTH", "Other"],
];

export const roleDropDown = [
  [null, "Choose your role"],
  ["Admin", "Admin"],
  ["Designer", "Designer"],
  ["Employee", "Employee"],
  // ["Owner", "Owner"],
];

export const employeeDropDown = [
  [null, "Choose number"],
  ["1 - 25", "1 - 25"],
  ["26 - 50", "26 - 50"],
  ["51 - 100", "51 - 100"],
  ["101 - 500", "101 - 500"],
  ["> 500", "> 500"],
];

export const otpChoices = [
  ["email", "Email"],
  ["mobile", "Phone"],
  ["both", "Email & Phone"],
];

export const inputTypesLists = [
  ["text", "Text"],
  ["number", "Number"],
  ["date", "Date"],
  ["email", "Email"],
];

export const identityProviderList = [
  ["Azure", "Azure AD"],
  ["Google", "Google"],
  ["AWS", "AWS"],
];

export const authenticationType = [
  ["BA", "Basic Auth"],
  // ["SA", "Secure Auth"],
];

export const imageSourceDropdown = [
  // [null, "Choose an Image Source"],
  ["CDN", "CDN"],
  ["UPLOAD", "Upload"],
];
