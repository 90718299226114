import React from "react";
import {
  Radio,
  FormControlLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Required from "../Required";
import { APP_DESIGN_MODES } from "../../../../../../common/utils/constants";

const RadioButton = ({ style, values, readOnly, ...props }) => {
  const radioStyle = makeStyles((theme) => style);
  const classes = radioStyle();
  return (
    <div className={`${classes?.container} ${classes?.dimensions}`}>
      {!values?.labelHide && (
        <Typography gutterBottom className={classes?.label}>
          {values?.label}
          <Required required={values?.required} />
        </Typography>
      )}
      {props.data.map(({ value, checked }) => (
        <div>
          <FormControlLabel
            className={classes?.radio}
            control={
              <Radio
                className={classes?.radio}
                checked={checked}
                name={value}
                disabled={
                  props.appDesignMode === APP_DESIGN_MODES.EDIT ||
                  props.mode === APP_DESIGN_MODES.PREVIEW ||
                  props.disabled
                }
                readOnly={readOnly}
                inputProps={{
                  "aria-label": "A",
                  style: {
                    border: "1px solid #ABB3BF",
                  },
                }}
              />
            }
            label={<Typography className={classes?.radio}>{value}</Typography>}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
