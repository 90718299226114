import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BlockPicker } from "react-color";
import { SketchPicker } from "react-color";
import { Popover } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 26,
    borderRadius: 3,
    padding: "11px 10px",
    border: "1px solid #ABB3BF",
    boxSizing: "border-box",
  },
  selectedHex: {
    color: "white",
    mixBlendMode: "difference",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
  },
}));

export default function ColorPicker(props) {
  const {
    identity,
    colorCallback,
    textColorCallback,
    color = "#091540",
    // selectedColor: defaultColor,
  } = props;
  const classes = useStyles();
  const [defaultColor, setDefaultColor] = useState(color);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [background, setBackground] = React.useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? identity : undefined;
  const [selectedColor, setSelectedColor] = React.useState(defaultColor);
  // const [background, setBackground] = React.useState(defaultColor);
  useEffect(() => {
    setSelectedColor(defaultColor);
  }, [defaultColor]);

  const handleChange = (color, event) => {
    colorCallback(color.hex);
    // if (identity === "layoutColor") {
    // } else if (identity === "textColor") {
    //   textColorCallback(color.hex);
    // } else if (identity === "backgroundColor") {
    //   colorCallback(color.hex);
    // } else if (identity === "borderColor") {
    //   colorCallback(color.hex);
    // }
  };

  const handleChangeComplete = (color, event) => {
    // setBackground(color.hex);
    setSelectedColor(color.hex);
  };

  return (
    <div style={{ ...props.style, flex: 1, cursor: "pointer" }}>
      <div
        id={identity}
        className={classes.root}
        onClick={handleClick}
        style={{ background: selectedColor }}
      >
        {/* <StopIcon style={{ color: selectedColor }} /> */}
        <span className={classes.selectedHex}>{selectedColor}</span>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <BlockPicker
          color={selectedColor}
          onChange={handleChange}
          onChangeComplete={handleChangeComplete}
        />
      </Popover>
    </div>
  );
}
