import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@material-ui/core";
import AccountCircleIconOutlined from "@material-ui/icons/AccountCircleOutlined";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import useStyles from "./style";
import AssignUserModal from "./AssignUserModal";
import CustomPromptBox from "../../../../common/components/CustomPromptBox/CustomPromptBox";
import { useQueryClient } from "react-query";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import NewUserModal from "./NewUserGroupModal";

const SingleUser = ({
  item,
  toggleRolePermissions,
  toggleRoleUsers,
  deleteMe,
}) => {
  const classes = useStyles();
  const [openAssignMembers, setOpenAssignMembers] = useState(false);
  const [groupData, setGroupData] = useState({});
  const [isDeleted, setIsDeleted] = React.useState(false);
  const [refetchQuery, setRefetchQuery] = useState(false);
  const [deleteUserGroupModal, setDeleteUserGroupModal] = useState(false);
  const [editUserGroupModal, setEditUserGroupModal] = useState(null);

  const queryClient = useQueryClient();

  const handleAssignMembers = () => {
    setOpenAssignMembers(true);
    setGroupData(item);
  };

  const handleRemoveGroup = async () => {
    deleteMe();
  };

  const closeAssignModal = (reloadOnEdit = false) => {
    setOpenAssignMembers(false);
    if (refetchQuery || reloadOnEdit) {
      queryClient.invalidateQueries(["allUserGroups"]);
      setRefetchQuery(false);
    }
  };

  return (
    <Grid item xs={4} sm={4} style={{ display: isDeleted ? "none" : "flex" }}>
      <div className={classes.moredetails}>
        <div
          style={{
            display: "flex",
            padding: "5px 20px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "0.5px solid rgba(238, 237, 242, 0.92)",
          }}
        >
          <Typography className={classes.heading5}>{item.name}</Typography>
          <div style={{ display: "flex", gap: "10px" }}>
            <EditOutlinedIcon
              className={classes.accounticon}
              onClick={() => setEditUserGroupModal("edit")}
            />
            <AccountCircleIconOutlined
              className={classes.accounticon}
              onClick={handleAssignMembers}
            />
            <DeleteOutlined
              className={classes.accounticon}
              // style={{ marginLeft: 10 }}
              onClick={() => setDeleteUserGroupModal(true)}
            />
          </div>
        </div>
        <div
          style={{
            padding: "10px 20px",
            height: 50,
            overflow: "overlay",
            paddingBottom: 30,
          }}
        >
          <Typography style={{ color: "#999999", fontSize: 12 }}>
            {item.description}
          </Typography>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            borderTop: "solid 0.3px #f8f8f8",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          }}
        >
          <Typography className={classes.linkText}>
            <b>{item?.users?.length || 0}</b>{" "}
            {item?.users?.length === 1 ? "member" : "members"}
          </Typography>
        </div>
      </div>
      {editUserGroupModal === "edit" && (
        <NewUserModal
          closeModal={() => setEditUserGroupModal(null)}
          id={item?.id || item?._id}
          name={item?.name}
          description={item?.description}
          mode={editUserGroupModal}
          reloadPage={closeAssignModal}
        />
      )}
      {openAssignMembers && (
        <AssignUserModal
          data={groupData}
          open={openAssignMembers}
          closeModal={closeAssignModal}
          openAssignMembers={openAssignMembers}
          setRefetchQuery={setRefetchQuery}
        />
      )}
      {deleteUserGroupModal && (
        <CustomPromptBox
          title="confirm deletion"
          actionText={
            "Kindly type “delete” in the text field below to continue this action. Note that you lose the user group and members assigned to it."
          }
          handleCloseDialog={setDeleteUserGroupModal}
          open={deleteUserGroupModal}
          confirmAction={() => handleRemoveGroup()}
        />
      )}
    </Grid>
  );
};

export default SingleUser;
