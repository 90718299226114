export const useStyles = (makeStyles) => {
  return makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    // necessary for content to be below app bar
    toolbar: theme?.mixins?.toolbar,

    content: {
      flexGrow: 1,
      padding: theme?.spacing(3),
    },

    apps: {
      float: "left",
      width: "32%",
      backgroundColor: "#0e174b",
      padding: 5,
      marginRight: 5,
      marginBottom: 5,
      borderRadius: 3,
      color: "#CCCCCC",
      cursor: "pointer",
      "&:hover": {
        background: "#0c7b93",
      },
    },
    sideSections: {
      padding: 15,
      marginBottom: 12,
      borderRadius: "3px 0 0 3px",
      color: "#ffffff",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.8,
      },
    },
    card: {
      textAlign: "left",
      // padding: 10,
      width: 269,
      // minWidth: 290,
      // maxWidth: 354,
      height: 194,
      backgroundColor: "#fff",
      boxShadow: "0 2px 6px 1px #75757540",
      userSelect: "none",
      display: "flex",
      flexDirection: "column",
      "&:hover": {
        boxShadow: "0 0 10px #ccc",
      },
      "& a": {
        textDecoration: "none",
      },
    },
    moreIcon: {
      color: "#000",
      cursor: "pointer",
      fontSize: 18,
    },
    cardTitle: {
      borderBottom: "solid 1px #ddd",
      height: 53,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      textTransform: "capitalize",
      padding: 10,
      gap: 10,
    },
    cardTitleIcon: {
      width: 37,
      minWidth: 37,
      height: 31,
      backgroundColor: "#FFE7E8",
      borderRadius: 6.5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        width: 26,
      },
    },
    cardTitleText: {
      whiteSpace: "break-spaces",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: "15px",
      color: "#303030",
      lineBreak: "anywhere",
      // overflow: "hidden",
      // height: "100%",
      // textOverflow: "ellipsis",
    },
    cardTitleBtns: {
      marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 10,
      marginRight: 5,
    },
    cardDescription: {
      height: 50,
      padding: 10,
      overflow: "hidden",
      fontWeight: 400,
      color: "#535353",
      lineHeight: 1.5,
      letterSpacing: "0.45px",
      fontSize: 12,
      "&:hover": {
        overflow: "overlay",
      },
    },
    cardBase: {
      padding: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "auto",
      height: 45,
    },

    cardBadge1: {
      backgroundColor: "#f3f2ec",
      border: "solid 0.5px #e7e6dc",
      padding: "2px 10px",
      fontSize: "0.85em",
      borderRadius: 4,
      color: "#656776",
      letterSpacing: "0.8px",
      fontWeight: 300,
    },
    cardBadge3: {
      backgroundColor: "#3cc0dd",
      border: "solid 0.5px #3cc0dd",
      color: "#FFFFFF",
      padding: "2px 10px",
      fontSize: "0.85em",
      borderRadius: 4,
      letterSpacing: "0.8px",
      fontWeight: 400,
      textShadow: "0.5px 0.5px 0.5px #888",
    },
    cardBadge2: {
      fontSize: 10,
      color: "#ffffff",
      padding: "3px 8px",
      minWidth: 60,
      textTransform: "capitalize",
      fontWeight: 500,
      backgroundColor: "#2457C1",
      borderRadius: 3,
    },
    cardBadge4: {
      fontSize: "0.85em",
      color: "#696969",
      padding: "3px 5px",
      textTransform: "capitalize",
      letterSpacing: "0.4px",
      fontWeight: 200,
    },

    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    tabsWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      "&::before": {
        content: '""',
        borderBottom: "solid 2px #eee",
        width: "100%",
        position: "absolute",
        bottom: 0,
      },
    },
    tabsBorder: {
      "&::after": {
        content: '""',
        borderBottom: "solid 2px #ddd",
        width: "100%",
        position: "absolute",
        bottom: 0,
      },
    },
    paginate: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "3em",
      marginTop: "2rem",
      "& > span": {
        color: "#2896FD",
        fontWeight: 400,
        lineHeight: "16px",
        cursor: "pointer",
        backgroundColor: "#fff",
        padding: "5px 20px",
        fontSize: 12,
        borderRadius: 20,
        boxShadow: "0 0 5px #ccc",
        zIndex: 1,
        "&:hover": {
          boxShadow: "none",
        },
      },
      "&:before": {
        content: '""',
        borderBottom: "solid 2px #eaeef2",
        width: "100%",
        position: "absolute",
        bottom: 17,
        // zIndex: -1,
      },
    },
    toTopIcon: {
      position: "fixed",
      right: 60,
      bottom: 125,
      background: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 50,
      height: 50,
      borderRadius: "50%",
      boxShadow: "0px 1px 4px rgba(111, 111, 111, 0.25)",
      cursor: "pointer",
    },
  }))();
};
