export const promptGeneratingText = [
  "Analysing your prompt, still generating! Hold on a bit...",
  "Generating the required fields! Hold on a bit...",
  "Polishing the components! Hold on a bit...",
  "Putting finishing touches! Hold on a bit...",
  // "Designing your page! Hold on a bit...",
  // "Your brand new page is almost ready! Hold on a bit...",
  // "Yay! We are ready! Thanks for using our AI generator",
];

export const ISGENERATING_DELAY = 2500;
export const ISREADY_DELAY = 3000;
