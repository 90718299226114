import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    maxWidth: 500,
  },

  title: {
    // fontFamily: "Inter",
    // fontStyle: "normal",
    // fontWeight: 500,
    fontSize: 12,
    // lineHeight: "132.24%",
    color: "#091540",
    fontWeight: 600,
    // margin: "40px 0 0",
    // display: "flex", */
    backgroundColor: "#fafafa",
    padding: "5px 8px",
    border: "solid 0.5px #ccc",
    borderRadius: 5,
  },
  usePlug: {
    fontSize: 12,
    // lineHeight: "132.24%",
    color: "#091540",
    fontWeight: 600,
  },
  phonenumber: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "132.24%",
    color: "#091540",
    top: 366,
    left: 707,
    position: "absolute",
    width: 79,
    height: 16,
    display: "flex",
    alignItems: "center",
  },
  phonenumberTextField: {
    position: "absolute",
    width: 410,
    height: 62,
    top: 394,
    left: 707,

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 16,

    display: "flex",
    alignItems: "center",
    color: "#ABB3BF",
  },
  customButton: {
    // backgroundColor: "#dd4400",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
  customButtonLabel: {
    textTransform: "capitalize",
    textDecoration: "none",
    fontSize: 12,
    fontWeight: 300,
    // lineHeight: 1,
  },

  formLabels: {
    paddingTop: theme?.spacing(3),
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "132.24%",
    display: "flex",
    color: "#091540",
  },

  formTextField: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 16,
    display: "flex",
    color: "#091540",
    backgroundColor: "#FFFFFF",
  },

  nameGrid: {
    [theme?.breakpoints?.down("sm")]: {
      margin: 0,
      "& > .MuiGrid-item": {
        padding: 0,
      },
    },
  },

  next: {
    fontFamily: "Inter",
    fontStyle: "normal",
    margin: theme?.spacing(4, 0),
    backgroundColor: "#062044",
    color: "#fff",
    textTransform: "none",
    borderRadius: 3,
    justifyContent: "right",
    padding: theme?.spacing(1, 5),

    "&$disabled": {
      background: "#E7E9EE",
      color: "#fff",
    },
  },
  selectPadding: {
    padding: "10.5px 14px",
    backgroundColor: "#ffffff",
  },
  disabled: {},

  checkedBox: {
    color: "#D2DEFF",
    "& .MuiSvgIcon-root": {
      fontSize: "22px",

      // fill: "#2457C1",
    },
  },
}));

export default useStyles;
