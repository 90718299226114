import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { catchErr } from "./catchErr";

/**
 * It's a custom hook that uses the useQuery hook from the react-query library to make an API call
 * @returns The response object from the useQuery hook.
 */
const useCustomQuery = ({
  apiFunc,
  onSuccess,
  onError,
  queryKey,
  enabled = true,
}) => {
  const history = useHistory();

  const response = useQuery(queryKey, apiFunc, {
    retry: 1,
    refetchOnWindowFocus: false,
    onError: (error) => {
      catchErr({
        err: error,
        alertResMessage: error?.response?.data?._meta?.error?.message,
        statuscode: error?.response?.data?._meta?.statuscode,
        history,
      });
      !!onError && onError(error?.response);
    },
    retryDelay: 3000,
    // onSuccess: (data) => onSuccess({ data }),
    onSuccess: (data) => {
      if (!data || data?.name === "Error") {
        catchErr({
          err: data?.stack,
          alertResMessage: "",
          statuscode: "",
          history,
        });
        !!onError && onError("Sorry an error occured.");
      } else onSuccess({ data });
    },
    enabled,
  });

  return response;
};

export default useCustomQuery;
