import { CustomAxios } from "../../../utils/CustomAxios";

export const updateUserFields = async ({ id, data }) => {
  return await CustomAxios()
    .put(`${process.env.REACT_APP_ENDPOINT}/users/${id}`, data)
    .then((res) => res.data);
};

export const updateUserAccount = async ({ id, data }) => {
  return await CustomAxios()
    .put(`${process.env.REACT_APP_ENDPOINT}/accounts/${id}`, data)
    .then((res) => res.data);
};
