import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import { verifyAuth } from "./store/actions";
import rootReducer from "./store/reducers";
import { composeWithDevTools } from "redux-devtools-extension";

export default function configureStore(persistedState) {
  const store = createStore(
    rootReducer,
    persistedState,
    // composeWithDevTools(applyMiddleware(thunkMiddleware))
    composeWithDevTools({ trace: true, traceLimit: 25 })(
      applyMiddleware(thunkMiddleware)
    )
  );
  store.dispatch(verifyAuth());
  return store;
}
