export const defaultFormStyles = {
  form: {
    border: "solid 0",
    overflow: "auto",
  },
  field: {
    fontSize: 14,
    width: 200,
    color: "#091540",
    background: "#FFFFFF",
    border: "solid 1px #0000003b",
    borderRadius: 4,
  },
  label: {
    color: "#999",
    fontSize: 14,
    marginTop: 0,
    marginBottom: 5,
    lineHeight: 1.3,
    fontweight: 300,
  },
  button: {
    borderStyle: "solid",
    width: 200,
    borderRadius: 4,
  },
  text: {
    fontSize: 14,
    fontWeight: 300,
    color: "#263238",
  },
  heading: {
    fontSize: 17,
    fontWeight: 400,
    color: "#999",
  },
};
