export const fakeData = [
  {
    name: "Descasio Limited",
    date: "21/09/2022",
    email: "abdulganiyy.ajayi@descasio.io",
    users: "31",
    liveApps: "43",
    integrations: "Google Sheet",
    lastLoggedIn: "28/10/2022 11:56pm",
    action: "View Details",
    isExpand: true,
    badgeCount: 4,
  },
  {
    name: "Plug Incorporated",
    date: "21/09/2022",
    email: "ayobami.aderemi@descasio.io",
    users: "31",
    liveApps: "43",
    integrations: "Mongo DB",
    lastLoggedIn: "22/11/2022 11:43am",
    action: "View Details",
    isExpand: false,
    badgeCount: 0,
  },
  {
    name: "Kenny & Sons Limited",
    date: "21/09/2022",
    email: "kehinde.shogbanmu@descasio.io",
    users: "31",
    liveApps: "43",
    integrations: "SQL",
    lastLoggedIn: "31/09/2022 11:36am",
    action: "View Details",
    isExpand: true,
    badgeCount: 3,
  },
  {
    name: "Odogwu Enterprise",
    date: "21/09/2022",
    email: "chime.princewill@descasio.io",
    users: "31",
    liveApps: "43",
    integrations: "Gmail",
    lastLoggedIn: "21/11/2022 10:56pm",
    action: "View Details",
    isExpand: true,
    badgeCount: 5,
  },
];

export const mockData = [
  {
    name: "Enterprise Plan",
    date: "21/09/2022",
    amount: "$2450",
    users: "31",
  },
  {
    name: "Business Plan",
    date: "21/09/2022",
    amount: "$4950",
    users: "31",
  },
  {
    name: "Premium Plan",
    date: "21/09/2022",
    amount: "$6950",
    users: "31",
  },
];

export const fakeGraphData = [
  { name: "Q1", x: 500 },
  { name: "Q2", x: 1000 },
  { name: "Q3", x: 1300 },
  { name: "Q4", x: 2500 },
];

export const fakeGraphDataTwo = [
  { name: "A", x: 30, y: 70 },
  { name: "B", x: 12, y: 88 },
  { name: "C", x: 15, y: 85 },
  { name: "D", x: 35, y: 65 },
  { name: "E", x: 54, y: 46 },
  { name: "F", x: 72, y: 28 },
  { name: "G", x: 32, y: 68 },
  { name: "H", x: 35, y: 65 },
  { name: "I", x: 54, y: 46 },
  { name: "J", x: 72, y: 28 },
  { name: "K", x: 32, y: 68 },
  { name: "L", x: 32, y: 68 },
];

export const integrationsData = [
  {
    name: "Google Sheet",
    count: 2,
  },
  {
    name: "Sendgrid",
    count: 6,
  },
  {
    name: "Salesforce",
    count: 1,
  },
  {
    name: "Twilio",
    count: 2,
  },
  {
    name: "Azure",
    count: 1,
  },
  {
    name: "Zendesk",
    count: 5,
  },
  {
    name: "Gmail",
    count: 2,
  },
  {
    name: "Trello",
    count: 3,
  },
  {
    name: "Clearbit",
    count: 3,
  },
  {
    name: "SQL",
    count: 2,
  },
  {
    name: "Jira",
    count: 4,
  },
  {
    name: "Twitter",
    count: 1,
  },
  {
    name: "Mongo DB",
    count: 1,
  },
  {
    name: "Stripe",
    count: 5,
  },
  {
    name: "LinkedIn",
    count: 13,
  },
  {
    name: "Termii",
    count: 2,
  },
  {
    name: "Github",
    count: 7,
  },
  {
    name: "Discord",
    count: 3,
  },
  {
    name: "Slack",
    count: 9,
  },
  {
    name: "Shopify",
    count: 1,
  },
  {
    name: "Twitch",
    count: 4,
  },
];
