import { font as regularFont } from "../../assets/js/pdfFonts/Inter-Regular-normal";
import { font as boldFont } from "../../assets/js/pdfFonts/Inter-Bold-normal";
import { font as blackFont } from "../../assets/js/pdfFonts/Inter-Black-normal";
import { font as extraBoldFont } from "../../assets/js/pdfFonts/Inter-ExtraBold-normal";
import { font as extraLightFont } from "../../assets/js/pdfFonts/Inter-ExtraLight-normal";
import { font as lightFont } from "../../assets/js/pdfFonts/Inter-Light-normal";
import { font as mediumFont } from "../../assets/js/pdfFonts/Inter-Medium-normal";
import { font as semiBoldFont } from "../../assets/js/pdfFonts/Inter-SemiBold-normal";
import { font as thinFont } from "../../assets/js/pdfFonts/Inter-Thin-normal";

export const InterFonts = {
  regular: regularFont,
  bold: boldFont,
  black: blackFont,
  extraBold: extraBoldFont,
  extraLight: extraLightFont,
  light: lightFont,
  medium: mediumFont,
  semiBold: semiBoldFont,
  thin: thinFont,
};
