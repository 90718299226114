/**
 * Rich Text Editor - Expand Toolbar Sample
 */
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  NodeSelection,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import "@syncfusion/ej2-react-buttons/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-richtexteditor/styles/material.css";
import * as React from "react";
import { infoToastify } from "../../../../../../../../common/utils/Toastify";
import { unrenderableVariables } from "../../../../utils/constants";
import { useSelector } from "react-redux";
import NodeIcons from "../../../../NodeIcons";

let items = [
  {
    template: `<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%">
                            <div class="e-tbar-btn-text" style="background-color: #3e9aad; color: white; padding: 0 5px; border-radius: 4px; font-size: 0.9em">
                                Variables
                            </div>
                        </button>`,
    undo: true,
    tooltipText: "Insert Symbol",
  },
  "Bold",
  "Italic",
  "Underline",
  "|",
  "Formats",
  "Alignments",
  "OrderedList",
  "UnorderedList",
  "|",
  "CreateLink",
  "Image",
  "|",
  "SourceCode",
  "|",
  "Undo",
  "Redo",
];

function RichTextEditor(props) {
  const { workflowTasks: allTasks, workflowCanvas } = useSelector(
    ({ workflows }) => workflows
  );

  const dialogObj = React.useRef(null);
  const rteObj = React.useRef(null);
  const selection = new NodeSelection();
  const [showRTE, setShowRTE] = React.useState(false);

  //  this is to solve the lingered erractic appearance issue
  React.useEffect(() => {
    setTimeout(() => {
      setShowRTE(true);
    }, 0);
  }, []);

  const variables = props.variables?.filter(
    (variable) => !unrenderableVariables.includes(variable.info?.group)
  );
  const [template, setTemplate] = React.useState(props?.emailBody || "");
  // console.log("propas", props);

  React.useEffect(() => {
    setTemplate(props?.emailBody || "");
  }, [props?.emailBody]);

  const onClick = () => {
    rteObj.current.ranges = selection.getRange(document);
    dialogObj.current.width = rteObj.current.element.offsetWidth * 0.5;
    dialogObj.current.content = document.getElementById("rteSpecial_char");
    dialogObj.current.dataBind();
    dialogObj.current.show();
  };
  const onInsert = () => {
    const activeEle = dialogObj?.current?.element?.querySelector(
      ".char_block.e-active"
    );
    dialogOverlay();
    if (activeEle) {
      const newEl = document.createElement("span");
      newEl.id = activeEle.id;
      newEl.style.display = "inline";
      newEl.style.borderRadius = "3px";
      newEl.style.backgroundColor = "lightgrey";
      newEl.style.padding = "3px 5px";
      newEl.style.fontSize = "0.85em";
      newEl.innerHTML = activeEle.innerHTML;
      newEl.contentEditable = false;
      newEl.setAttribute("data-is-variable", true);
      rteObj.current.ranges.insertNode(newEl);

      // this.ranges.insertNode(document.createTextNode(activeEle.textContent));
    }
  };
  const onCancel = (e) => {
    const activeEle = dialogObj?.current?.element?.querySelector(
      ".char_block.e-active"
    );
    if (activeEle) {
      activeEle.classList.remove("e-active");
    }
    dialogObj.current.hide();
  };

  const onClickToCopy = (variable) => {
    if (variable) {
      const variableTocopy = `{{${variable?.info?.name}-${variable?.id?.slice(
        -6
      )}}}`;
      navigator.clipboard.writeText(variableTocopy);
    }
    dialogOverlay();
    infoToastify("Variable name copied");
  };

  items = [
    {
      template: `<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%">
                              <div class="e-tbar-btn-text" style="background-color: #3e9aad; color: white; padding: 0 5px; border-radius: 4px; font-size: 0.9em">
                                  Variables
                              </div>
                          </button>`,
      undo: true,
      click: onClick.bind(this),
      tooltipText: "Insert Symbol",
    },
    "Bold",
    "Italic",
    "Underline",
    "|",
    "Formats",
    "Alignments",
    "OrderedList",
    "UnorderedList",
    "|",
    "CreateLink",
    "Image",
    "|",
    "SourceCode",
    "|",
    "Undo",
    "Redo",
  ];
  let toolbarSettings = {
    items,
    // type: "Expand",
  };

  const dialogCreate = () => {
    const dialogCtn = document.getElementById("rteSpecial_char");
    dialogCtn.onclick = (e) => {
      const target = e.target;
      const activeEle = dialogObj?.current?.element?.querySelector(
        ".char_block.e-active"
      );
      if (target.classList.contains("char_block")) {
        target.classList.add("e-active");
        if (activeEle) {
          activeEle.classList.remove("e-active");
        }
      }
    };
    // this.rteObj.value = "<p></p>";
  };

  const header = "Insert Variable";

  let dlgButtons = [
    // {
    //   buttonModel: { content: "Insert", isPrimary: true },
    //   click: onInsert.bind(this),
    // },
    { buttonModel: { content: "Cancel" }, click: onCancel },
  ];

  const dialogOverlay = () => {
    const activeEle = dialogObj?.current?.element?.querySelector(
      ".char_block.e-active"
    );
    if (activeEle) {
      activeEle.classList.remove("e-active");
    }
    dialogObj.current.hide();
  };

  const VariableSubText = (variable) => {
    const varSourceId = variable?.info?.parent;
    const varSource =
      allTasks?.[varSourceId] ||
      workflowCanvas.find((c) => c.id === varSourceId);

    return (
      <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
        <NodeIcons style={{ fontSize: 12 }} nodeType={varSource?.type} />{" "}
        {varSource?.name || `{ unnamed ${varSource?.type} node }`}
      </div>
    );
  };

  return showRTE ? (
    <div className="control-pane">
      <div
        className="control-section e-rte-custom-tbar-section"
        id="rteCustomTool"
      >
        <div className="rte-control-section" id="rteSection">
          <RichTextEditorComponent
            blur={() => {
              props.holdBody(rteObj.current.value);
            }}
            height={250}
            id="defaultRTE"
            ref={(scope) => (rteObj.current = scope)}
            valueTemplate={template}
            toolbarSettings={toolbarSettings}
          >
            {/* <p>
              The Rich Text Editor component is WYSIWYG ("what you see is what
              you get") editor that provides the best user experience to create
              and update the content. Users can format their content using
              standard toolbar commands.
            </p>
            <p>
              <b>Key features:</b>
            </p>
            <ul>
              <li>
                <p>Provides modes</p>
              </li>
              ß
              <li>
                <p>Capable of handling markdown editing.</p>
              </li>
              <li>
                <p>
                  Contains a modular library to load the necessary functionality
                  on demand.
                </p>
              </li>
              <li>
                <p>Provides a fully customizable toolbar.</p>
              </li>
              <li>
                <p>
                  Provides HTML view to edit the source directly for developers.
                </p>
              </li>
              <li>
                <p>Supports third-party library integration.</p>
              </li>
              <li>
                <p>Allows preview of modified content before saving it.</p>
              </li>
              <li>
                <p>
                  Handles images, hyperlinks, video, hyperlinks, uploads, etc.
                </p>
              </li>
              <li>
                <p>Contains undo/redo manager.</p>
              </li>
              <li>
                <p>Creates bulleted and numbered lists.</p>
              </li>
            </ul> */}
            <Inject
              services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]}
            />
          </RichTextEditorComponent>
          <DialogComponent
            id="customTbarDlg"
            ref={(scope) => (dialogObj.current = scope)}
            buttons={dlgButtons}
            overlayClick={dialogOverlay.bind(this)}
            header={header}
            visible={false}
            showCloseIcon={false}
            target={"#rteSection"}
            // height={this.height}
            // maxHeight={this.maxHeight}
            created={dialogCreate.bind(this)}
            isModal={true}
          />
          <div id="customTbarDialog" style={{ display: "none" }}>
            <div id="rteSpecial_char">
              {variables?.map((variable, indexKey) => (
                <div
                  key={indexKey}
                  className="char_block"
                  id={variable.id}
                  style={{
                    padding: "5px",
                    borderRadius: 5,
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#EEE",
                    },
                  }}
                  title={`${variable?.info?.nodeType}: ${variable?.info?.parent}`}
                  onClick={() => onClickToCopy(variable)}
                >
                  <div>{variable.info?.name || "unnamed variable"}</div>
                  <div
                    style={{
                      fontSize: 10,
                      borderTop: "solid 0.5px #eee",
                      paddingTop: 3,
                      marginTop: 3,
                      color: "#888",
                    }}
                    title={`${variable.nodeType}: ${variable.parent}`}
                    //onClick={() => onClickToCopy(variable)}
                  >
                    {VariableSubText(variable)}
                  </div>
                </div>
              ))}
              {!variables?.length && (
                <em style={{ color: "#aaa" }}>No variables</em>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default RichTextEditor;
