import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Input,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import FileUpload from "../../FileUpload";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { uploadFile } from "../../../../../../../common/helpers/LiveData";
import { inputTableColumnTypes } from "../../../RightSidebar/components/components/InputTableColumns";
import { separateNumbersWithComma } from "../../../../../../../common/helpers/helperFunctions";

const CELL_MIN_WIDTH = 140;

const InputTableCell = React.memo(
  ({
    type: cellSectionType,
    row,
    col,
    rowIndex,
    valuesData,
    values,
    setValuesData,
    setCellValue,
    shouldAutoFocus,
    readOnly,
    dataType,
    ...props
  }) => {
    const dispatch = useDispatch();
    const [reRenderKey, setReRenderKey] = useState(null);

    const formatTableCellFunc = () => {
      const formattableCells = inputTableColumnTypes
        .map((item) => item[0])
        .filter(
          (item) => !["dateTime", "fileUpload", "computed"].includes(item)
        );
      if (formattableCells.includes(col.inputType)) {
        setValuesData({
          ...valuesData,
          columns: valuesData.columns.map((column) => ({
            ...column,
            [col.id]: separateNumbersWithComma(
              row[col.id],
              values?.isFormatted
            ),
          })),
        });
      }
    };

    useEffect(() => {
      setReRenderKey(v4());
    }, [col.cellOptions]);

    const CellInputText = (
      <Input
        inputProps={{
          style: {
            textAlign: `${col?.inputTextAlign}`,
          },
        }}
        style={{
          color: "#091540",
          borderRadius: 3,
          position: "relative",
          border: "1px solid #091540",
          fontSize: 12,
          padding: "3px 12px",
          width: "100%",
          // minWidth: CELL_MIN_WIDTH * (col?.relWidth || 1),
          ...(props?.isDocument
            ? {}
            : { minWidth: CELL_MIN_WIDTH * (col.relWidth || 1) }),
        }}
        rows={col.textAreaNumRows || 4}
        multiline={col.inputType === "textArea"}
        className={`${readOnly ? "read-only" : ""}`}
        value={separateNumbersWithComma(
          cellSectionType === "aggregate"
            ? valuesData?.aggregateCells?.[row.id]
            : valuesData?.columns?.[rowIndex]?.[col.id],
          true,
          { ...col, dataType }
        )}
        onBlur={(e) => {
          formatTableCellFunc();
        }}
        onChange={(e) => {
          e.persist();
          setCellValue &&
            setCellValue(e.target.value, rowIndex, col, cellSectionType);
        }}
        // style={{ width: "100%" }}
        disabled={
          cellSectionType === "aggregate"
            ? row.inputType !== "variable"
            : col.inputType === "computed"
        }
        readOnly={readOnly}
        type={col?.inputTextType}
      />
    );

    const CellDateTime = (
      <Input
        style={{
          color: "#091540",
          borderRadius: 3,
          position: "relative",
          border: "1px solid #091540",
          fontSize: 12,
          padding: "3px 12px",
          width: "100%",
          display: "inline-grid",
          // minWidth: CELL_MIN_WIDTH * (col?.relWidth || 1),
          ...(props?.isDocument
            ? {}
            : { minWidth: CELL_MIN_WIDTH * (col.relWidth || 1) }),
        }}
        type="datetime-local"
        className={`${readOnly ? "read-only" : ""}`}
        value={
          cellSectionType === "aggregate"
            ? valuesData?.aggregateCells?.[row.id]
            : valuesData?.columns?.[rowIndex]?.[col.id]
        }
        onChange={(e) => {
          e.persist();
          setCellValue &&
            setCellValue(e.target.value, rowIndex, col, cellSectionType);
        }}
        // style={{ width: "100%" }}
        disabled={
          cellSectionType === "aggregate"
            ? row.inputType !== "variable"
            : col.inputType === "computed"
        }
        readOnly={readOnly}
        // required={col?.required}
        // autoFocus={!!shouldAutoFocus}
        // focused={!!shouldAutoFocus}
      />
    );

    const CellDropdownSelect = (
      <Select
        key={reRenderKey}
        style={{
          color: "#091540",
          borderRadius: 3,
          position: "relative",
          border: "1px solid #091540",
          fontSize: 12,
          padding: "3px 12px",
          width: "100%",
          // minWidth: CELL_MIN_WIDTH * (col?.relWidth || 1),
          ...(props?.isDocument
            ? {}
            : { minWidth: CELL_MIN_WIDTH * (col.relWidth || 1) }),
        }}
        className={`${readOnly ? "read-only" : ""}`}
        value={separateNumbersWithComma(
          cellSectionType === "aggregate"
            ? valuesData?.aggregateCells?.[row.id]
            : valuesData?.columns?.[rowIndex]?.[col.id]
        )}
        onChange={(e) => {
          e.persist();
          setCellValue &&
            setCellValue(e.target.value, rowIndex, col, cellSectionType);
        }}
        // style={{ width: "100%" }}
        disabled={
          cellSectionType === "aggregate"
            ? row.inputType !== "variable"
            : col.inputType === "computed"
        }
        readOnly={readOnly}
        // required={col?.required}
        // autoFocus={!!shouldAutoFocus}
        // focused={!!shouldAutoFocus}
      >
        {col.cellOptions?.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
            {separateNumbersWithComma(option, values?.isFormatted)}
          </MenuItem>
        ))}
      </Select>
    );

    const CellCheckbox = (
      // <FormGroup>
      <FormControl
        style={{ ...(props?.isDocument ? {} : { minWidth: CELL_MIN_WIDTH }) }}
        // required={col?.required}
      >
        <RadioGroup
          className={`${readOnly ? "read-only" : ""}`}
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
        >
          {col.cellOptions?.map((option, index) => (
            <FormControlLabel
              key={index}
              className={`${readOnly ? "read-only" : ""}`}
              control={
                <Radio
                  name={`${option}-${index}`}
                  value={option}
                  onChange={(e) => {
                    e.persist();
                    setCellValue &&
                      setCellValue(
                        e.target.value,
                        rowIndex,
                        col,
                        cellSectionType
                      );
                  }}
                  // name={option}
                />
              }
              label={
                <Typography className={""} style={{ textAlign: "left" }}>
                  {option}
                  {separateNumbersWithComma(option, values?.isFormatted)}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
        {/* </FormGroup> */}
      </FormControl>
    );

    const cellFileUpload = (
      <FileUpload
        id={`${col.id}-${rowIndex}`}
        dispatch={dispatch}
        values={{
          // required: `${col?.required}`,
          buttonText: "Click to upload",
          labelHide: true,
          numOfFiles: 1,
          maxFileSize: 2, //(MB)
        }}
        style={{
          button: {
            width: "100%",
            minWidth: CELL_MIN_WIDTH,
            height: 35,

            fontSize: 12,
            fontWeight: 300,
            color: "#ffffff",
            textAlign: "center",
            textTransform: "none",

            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: 5,
            borderColor: "#091540",
            backgroundColor: "#091540",

            /* color: "#ffff",
      cursor: "pointer",
      border: "1px solid #010A43",
      padding: "10px 0",
      fontSize: "12px",
      variant: "filled",
      background: "#010A43",
      "&:hover": {
        color: "#010A43",
      },
      "&:disabled": {
        color: "#ffff",
      }, */
          },
        }}
        onChange={(e) => {
          if (e && setCellValue) {
            setCellValue(e, rowIndex, col, cellSectionType);
          }
        }}
        uploadFile={uploadFile}
        val={valuesData?.columns?.[rowIndex]?.[col.id]}
        fromInputTable
        readOnly={readOnly}
        appDesignMode={props?.appDesignMode}
      />
    );

    switch (col.inputType) {
      case "computed":
        return CellInputText;

      case "inputText":
        return CellInputText;

      case "textArea":
        return CellInputText;

      case "dropdownSelect":
        return CellDropdownSelect;

      case "dateTime":
        return CellDateTime;

      case "checkbox":
        return CellCheckbox;

      case "fileUpload":
        return cellFileUpload;

      default:
        if (cellSectionType === "aggregate") {
          return CellInputText;
        }
        break;
    }
  }
);

export default InputTableCell;
