export const roleOptions = [
  {
    name: "Admin",
    description:
      "The admin role has access to all the permissions available here",
    permissions: [
      "Can create, edit, view, and delete users management list.",
      "Can create, edit, view, and delete user groups.",
      "Can create, edit, view, and delete user's account.",
      "Can create, edit, view, and delete apps created.",
      "Can create, edit, view, and delete datasheets list items.",
      "Can create, edit, view, and delete app workflows.",
      "Can create, edit, view, and delete tasks of apps running.",
      "Can create, edit, view, and delete screens of apps created.",
      "Can create, edit, view, and delete app items.",
      "Can view templates available for apps.",
      "Can create, edit, view, and delete app categories.",
      "Can create, edit, view, and delete view workflow instances.",
      "Can create, edit, view, and delete google apis integrations.",
    ],
  },
  {
    name: "Employee",
    description:
      "The Employee role has access to all the permissions available here",
    permissions: [
      "Can view and edit users management list.",
      "Can view user groups.",
      "Can view apps created.",
      "Can view datasheets section.",
      "Can view app workflows.",
      "Can view tasks of apps running.",
      "Can view screens of apps created.",
      "Can view app items.",
      "Can view templates available for apps.",
      "Can view app categories.",
      "Can view, create, edit, and delete app items.",
      "Can view templates available for apps.",
      "Can view app categories.",
    ],
  },
  {
    name: "Designer",
    description:
      "The Designer role has access to all the permissions available here",
    permissions: [
      "Can view and edit users management list.",
      "Can view user groups.",
      "Can create, edit, view, and delete apps created.",
      "Can create, edit, view, and delete datasheets list items.",
      "Can create, edit, view, and delete app workflows.",
      "Can create, edit, view, and delete tasks of apps running.",
      "Can create, edit, view, and delete screens of apps created.",
      "Can create, edit, view, and delete app items.",
      "Can templates available for apps.",
      "Can view app categories.",
      "Can create, edit, view, and delete view workflow instances.",
      "Can create, edit, view, and delete google apis integrations.",
    ],
  },
];

// [Employee, Designer, Admin, PlugAdmin]

// PlugAdmin

// "Can create, edit, and view users management list.",
// "Can view user groups.",
// "Can create, edit, view, and delete apps created.",
// "Can create, edit, view, and delete datasheets list items.",
// "Can create, edit, view, and delete app workflows.",
// "Can create, edit, view, and delete tasks of apps running.",
// "Can create, edit, view, and delete screens of apps created.",
// "Can create, edit, view, and delete app items.",
// "Can create, edit, view, and delete templates available for apps.",
// "Can create, edit, view, and delete app categories.",
// "Can create, edit, view, and delete view workflow instances.",
// "Can create, edit, view, and delete google apis integrations.",
