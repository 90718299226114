import React from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGoogleLogout } from "react-google-login";
import { useQueryClient } from "react-query";

import { logoutClearLocalStorage } from "../../../common/helpers/helperFunctions";
import {
  logoutError,
  logoutUser,
  receiveLogout,
} from "../../../../store/actions";

const Logout = (props) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onLogoutSuccess = (res) => {
    logoutClearLocalStorage(history, false);
    dispatch(receiveLogout());
  };

  const onFailure = () => {
    dispatch(logoutError());
  };

  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
    onLogoutSuccess,
    onFailure,
  });

  const handleSignout = (event) => {
    queryClient.removeQueries(["inAppNotificationBadgeCount"]); // clear cache notification badge count on logout
    dispatch(logoutUser({ signOut, instance }, history));

    event.preventDefault();
  };

  return (
    <div
      onClick={handleSignout}
      ref={props?.logOutRef}
      style={{ marginTop: 20 }}
    >
      {props.children}
    </div>
  );
};

export default Logout;
