export const refreshTokenSetUp = async ({ res, googleSignInMutate, data }) => {
  // timing to renew access toen
  let refreshTiming = (res?.tokenObj?.expires_in || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {
    try {
      const newAuthRes = await res?.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;

      // saveUserToken(newAthusRes.access_token)
      setTimeout(refreshToken, refreshTiming);
    } catch (err) {}
  };

  setTimeout(refreshToken, refreshTiming);

  await googleSignInMutate(data);
};
